import { Helmet, HelmetProvider } from 'react-helmet-async';
import Title from "../../components/Title/Title";
import "./Live.scss"
import { useContext, useEffect } from "react";
import { LoadingContext } from "../../App";


const Live = () => {
    const { loading, setLoading } = useContext(LoadingContext)

    useEffect(() => {
        setLoading(false)
    }, [setLoading])

    // useEffect(() => {
    //     const element = document.querySelector('.live-iframe') as HTMLIFrameElement;
    //     if(element) {
    //         element.addEventListener('load', () => {
    //             const iframeWindow = element.contentWindow;
    //             iframeWindow?.scrollTo(0, 2400);
    //         })
    //     } 
    // }, [])

    return(
        <>
            <HelmetProvider>
                <div>
                    <Helmet>
                        <title>Live - Tradițional TV</title>
                        <meta name="description" content="Live - Tradițional TV" />
                    </Helmet>
                </div>
            </HelmetProvider>
            
            <Title text="Live" />
            <div className="live-content">
            <p style={{ fontWeight: 'bold' }}>Puteți viziona transmisia live a postului Tradițional TV, accesând link-ul:&nbsp;</p>
            <p style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }} onClick={() => window.open('https://rds.live/TRADITIONAL-TV/')}>rds.live/TRADITIONAL-TV/</p>
            </div>
        </>
    )
}

export default Live;

{
    /*
    <p>Puteți viziona transmisia live a postului Tradițional TV, derulând în jos în următoarea fereastră:</p>
            <br/><br/>
            <iframe src="//ok.ru/videoembed/3376394215012" allowFullScreen title="Live" className="live-iframe"></iframe> 
        
    */
}

{/* <div style={{ overflow: 'hidden', position: 'relative' }}>
<iframe src="https://rds.live/traditional-tv/" width="640" height="360" allowFullScreen title="Live"
style={{ position: 'absolute', clip: 'rect(500px, 0, 500px, 0)', top: '-500px', left: 0}}></iframe>
</div> 
    <meta http-equiv="Content-Security-Policy" content="frame-acestors 'none'">
    <meta http-equiv="X-Frame-Options" content="SAMEORIGIN">
*/

}