import "./Loader.scss"

const Loader = () => {
    return(
        <div className="loader-container">
            <span className="loader"></span>
        </div>
    )
}

export default Loader;