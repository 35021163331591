
import Firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAFleypjsdjPojPbqXKjcdMuGJYGGZ4v7c",
  authDomain: "traditionaltv-84ca7.firebaseapp.com",
  projectId: "traditionaltv-84ca7",
  storageBucket: "traditionaltv-84ca7.appspot.com",
  messagingSenderId: "495036778731",
  appId: "1:495036778731:web:d509d0bacce44da96cd6ae",
  measurementId: "G-PVZZ8219Y4"
};

// Initialize Firebase
const firebase = Firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export { firebase, db };