export enum TraditionalTvRoutesNames {
    ACASA = "ACASA",
    STIRI = "STIRI",
    EMISIUNI = "EMISIUNI",
    TRADITIONALIDESEAMA = "TRADITIONALIDESEAMA",
    PROGRAM = "PROGRAM",
    CONTACT = "CONTACT",
    LIVE = "LIVE",
    ECHIPA = "ECHIPA",
    DESPRECOMUNITATE = "DESPRECOMUNITATE",
    CONFIDENTIALITATE = "CONFIDENTIALITATE",
    TERMENICONDITII = "TERMENICONDITII",
    INFORMATII = "INFORMATII",
    CODCONDUITA = "CODCONDUITA",
    STIRIITEM = "STIRIITEM",
}